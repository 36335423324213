import React, { useRef, Suspense } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import * as THREE from 'three';
import { Octahedron, Text, Loader, Stars, Html, Float, Lightformer, CubicBezierLine, ContactShadows, Line, Environment, MeshTransmissionMaterial } from "@react-three/drei"
import { EffectComposer, N8AO, TiltShift2 } from "@react-three/postprocessing"
import { Route, Link } from "wouter"
import { easing } from "maath"
import "../App.css";

const Scene = () => {

  const scene = useRef();


  useFrame(() => {
    scene.current.rotation.y += 0.02;
    scene.current.rotation.x += 0.00;
    scene.current.rotation.z += 0.002;
  });
  return (
    <group ref={scene}>
      <Octahedron
        castShadow
        receiveShadow
        args={[1]}
      >
        <meshStandardMaterial roughness={0.75} emissive="#404057" />
      </Octahedron>
    </group>
  );
};

const Torus = (props) => (
  <mesh receiveShadow castShadow {...props}>
    <torusGeometry args={[4, 1.2, 128, 64]} />
    <MeshTransmissionMaterial backside backsideThickness={5} thickness={2} />
  </mesh>
)

function Rig() {
  useFrame((state, delta) => {
    easing.damp3(
      state.camera.position,
      [Math.sin(-state.pointer.x) * 5, state.pointer.y * 3.5, 15 + Math.cos(state.pointer.x) * 10],
      0.2,
      delta,
    )
    state.camera.lookAt(0, 0, 0)
  })
}

const Home = () => {
  return (
    <>
      <Canvas>
        <Suspense fallback={null}>
            <Float floatIntensity={2}>
              <Route path="/">
                <Torus />
              </Route>
              <Route path="/book">
                <Torus />
              </Route>
            </Float>
            <Environment preset="city">
              <Lightformer intensity={8} position={[10, 5, 0]} scale={[10, 50, 1]} onUpdate={(self) => self.lookAt(0, 0, 0)} />
            </Environment>
            <EffectComposer disableNormalPass>
              <N8AO aoRadius={1} intensity={2} />
              <TiltShift2 blur={0.2} />
            </EffectComposer>
            <Rig />
            <Scene />
        </Suspense>
        <spotLight position={[20, 20, 10]} penumbra={1} castShadow angle={0.2} />
        <Stars radius={40} depth={40} count={5000} factor={4} saturation={1} fade speed={2} />
      </Canvas>
      <Loader />
    </>
  );
};

export default Home;

