import React from 'react'

const Footer = () => {

  const link = 'https://www.amazon.com/ETHEREUM-comprender-aplicaciones-descentralizadas-inteligentes/dp/B0CH2FPHSJ'

  return (
    <div style={{ position: 'absolute', top: 0, left: 0, pointerEvents: 'none', width: '100%', height: '100%' }}>
      <a href={link} className="box" style={{ position: 'absolute', bottom: 40, right: 40, fontSize: '13px' }}>get the book.</a>
    </div>
  )
}

export default Footer;
